.dropDownStyle {
    float:right;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.3em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
}


.sliderHeading{
                    direction:"row";
                    gap:"small";
                    width:"small";
                    padding-top:'small';
                    align-Self:"center"
}
.required {
    color: red;
  }

.previousButton{
   border-radius: 100%;
}
.nextButton{
    border-radius: 100%;
}

.previousIcon{
    padding-top: 5px;
 }
 .nextIcon{
    padding-top: 5px;
 }

