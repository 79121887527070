/* side-nav-common */
.side-nav-common{
  position: fixed;
  background-color: #001E2E;
  padding: 0;
  font-size: 14px;
  box-shadow: 8px 0px;
  color: #EFEFEF;
  max-width: inherit;
  transition: .5s;
  &__logo {
    padding-left: 30px;
    padding-right: 32px;
    padding-bottom: 21px;

  }
  &__list {
    height: 44.5vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__list-collapse {
    height: 100%;
    a, #activity, #timecards, #job {
      &:hover {
        background-color: #9CDAD7;
        color: black;
      }
      &:focus {
        outline: none;
      }
    }
  }
  & .nav-item {
    min-height: 20px;
    line-height: 35px;
    font-size: 14px;

   & .dropdown-menu{
    left: 0px;
    position: absolute;
    z-index:999;
    }

    & .dropdown-item {
      line-height: 1.4 !important;
      cursor: pointer;
    }

    }
    .nav-item:hover{
    color:#4CBCB7;
    }
    .nav-item:active{
     background-color: #4CBCB7;
    }
  & .nav-link {
    padding: 0;
  }
  & .dropdown {
    background-color: #001E2E;
    border-top:1px solid #001E2E;
  }
  a {
    color: #fff;
    display: block;
    font-weight: 500;
    font-size: 14px;
  }
  span {
    color: white;
  }

  li {
    &:hover {
      background-color:#4CBCB7;
      a {
        color: #fff;
      }
      span {
      color: #fff;
    }
  }
    }
}

@media screen and (min-height: 621px) and (max-height: 715px){
  .side-nav-business-list{
    height: 42vh !important;  
  }
}

@media screen and (max-height: 620px){
  .side-nav-business-list{
    height: 39vh !important;  
  }
}

@media screen and (min-height: 665px) and (max-height: 700px){
  .side-nav-flap-list{
    height: 41vh !important;  
  }
}

@media screen and (max-height: 664px){
  .side-nav-flap-list{
    height: 38vh !important;  
  }
}

.side-nav-common__list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
  background: transparent;
}
.side-nav-common__list::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  background: transparent;
}

.side-nav {
   & .dropdown-menu{
    transform: translateX(225px) translateY(-93%) translateZ(0px) !important;
    top: unset !important;
    }

}
/* side-nav */
.collapsed-side-nav  {
   & .dropdown-menu{
    transform: translateX(58px) translateY(-105%) translateZ(0px) !important;
    top: unset !important;
    }
}

/* business-admin */
.business-side-nav {
  & .dropdown-menu{
    transform: translateX(225px) translateY(-90px) translateZ(0px) !important;
    }
}

.business-collapsed-side-nav  {
   & .dropdown-menu{
    transform: translateX(58px) translateY(-147px) translateZ(0px) !important;
    }
}
.border-none{
  border: none !important;
}

.dashboard-mainbody{
  height: 100vh;
  overflow-y: hidden;
}

/* content area */
  .my-auto-refresh{
    margin-bottom: auto !important;
    margin-top: -20px !important;
}
  .refresh-style{
    color:#09819A;
    padding-top:15px !important;
    padding-right: 2em !important;
    opacity:1;
  }
  .filter {
  & .invalid-feedback {
    display: none;
  }
  & .report-filter {
    & .invalid-feedback {
      display: block;
    }
  }
  #team .dropdown, #employee .dropdown, .department-index .dropdown {
    z-index: 3 !important;
  }
  #branch {
    max-height: 79px;
    overflow-y: auto;
    overflow-x: hidden
  }
  .activity.dropdown-toggle.btn-accent {
    max-width: 410px !important;
  }

  .timecard_payroll .dropdown-toggle.btn-accent {
    max-width: 340px !important;
  }

  .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    top:100% !important;
  }
  .checkbox .form-group .col {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .dropdown-toggle.btn-accent {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  form {
    margin-top: 0;
  }
  & .btn {
    border: none;
    outline: 0;
    line-height: 1.5em;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 3px;
    &:focus {
      box-shadow: none !important;
    }
  }
  .btn-accent {
    background: $brand-primary;
    /* width: 100px !important; */
    margin-right: 8px;
    color: $white;
    border: none;
    outline: 0;
    line-height: 1.5em;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 3px;
    &:disabled {
      background: #adabab;
      color: $white;
    }
  }
  .department-checkbox {
    line-height: 0;
    margin-top: 2px;
    & .form-check {
      margin-bottom: 0
    }
    &.form-text {
     display: none !important;
    }
    &.invalid-feedback {
      display: none !important;
    }
    #manual_correction {
      margin-top: 0px !important;
    }
    #show_department_team {
      margin-top: 0px !important;
    }
  }
  .input-group {
    width: 230px;
  }
}

.employee_listing_sidebar .dropdown-menu{
  max-height: 200px;
  overflow-y: auto;
  top:100% !important;
  width: 100%;
}
.employee_listing_sidebar{
  button.dropdown-toggle {
    width: 100%;
}
}
.task-search .input-group{
  width:240px !important;
}

.form-inline {
  .row {
    padding-right: 0.5em;
  }
}

/* scroll bar overrite */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
  background: white;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);
  background: white;
}

/* placeholder color */
::-webkit-input-placeholder {
  color: #aaa !important;
}
:-moz-placeholder {
  color: #aaa !important;
  opacity: 1;
}
::-moz-placeholder {
  color: #aaa !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #aaa !important;
}
::-ms-input-placeholder {
  color: #aaa !important;
}

button, input, optgroup, select, textarea {
  font-family: 'MuseoSans', Roboto !important;
}
button:focus {
  outline: none;
}
.outline-none:focus {
  outline: none;
}

.error-text {
  color: #d9534f;
  min-height: 20px;
  font-weight: bold;
}

.card-block{
  flex: 1 1 auto;
  padding: 1.25rem;
}

.bgRed {
  background: #FFCDD2;
}

.redColor {
  color: red;
}
.fontLarge {
  font-size: 18px;
}
.input-icon {
  position: relative;
  float: right;
  right: 13px;
  z-index: 999;
  margin-top: -51px;
  padding-top: 1px !important;
  padding-left: 4px !important;
  cursor: pointer;
  &__clear {
    @extend .input-icon;
    position: absolute;
    right: 33px;
    margin-top: -3px;
    z-index: 3;
  }
  &__clear-logo {
    @extend .input-icon;
    position: absolute;
    right: 28px;
    top: 65px;
  }
}
.password-icon {
  position: absolute;
  top: 32px;
  z-index: 9;
  right: 8px;
}
.password-icon-custom {
  position: absolute;
  top: 33px;
  z-index: 9;
  right: 8px;
}
.password-icon34 {
  @extend .password-icon;
  top: 34px !important;
}
.full-height {
  min-height: 100vh;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed !important;
}
.width-sm {
  min-width: 120px;
}
.toastify-content--success {
  background: #4CBCB7 !important;
  border-left: 6px solid #4CBCB7 !important;
  color: white;
}
.toastify-content--error {
  background: #d9534f !important;
  border-left: 6px solid #d9534f !important;
  color: #fff;
}
.toastify-content--info {
  background: #3498db !important;
  border-left: 6px solid #3498db !important;
  color: #fff;
}
.toastify__close {
  color: white !important;
}

.Select-menu {
  max-height: 140px !important;
}
.button2link {
  background: none !important;
  border: none;
  padding: 0px;
  font-size: inherit;
  &:hover {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &:active{
   outline: none !important;
   box-shadow: none;
  }
  &:focus{
   outline: none !important;
   box-shadow: none;
  }
}

.report-filter {
  & .form-group {
    margin-right: 12px;
  }
  form {
    margin-top: 0;
  }
  & .btn {
    border: none;
    outline: 0;
    line-height: 1.5em;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 3px;
    &:focus {
      box-shadow: none !important;
    }
  }

  &__payroll-filter .Select {
    width: 250px;
  }

  .btn-accent {
    background: #09819A;;
    margin-right: 8px;
    margin-bottom:24px;
    color: #fff;
    border: none;
    outline: 0;
    line-height: 1.5em;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 3px;
    &:disabled {
      background: #adabab;
      color: #fff;
    }
  }
  .input-group {
    height: 36px;
  }
  .Select-input {
    width: 120px;
  }
}

.tooltip-inner {
  text-align: left !important;
  display: block !important;
}

.qb-radio .form-check-label {
  margin-right: 40px;
}

.dropdown-arrow {
  top: 37px;
  right: 12px;
  border-color: #001E2E transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  height: 0;
  width: 0;
  transform: rotateZ(0deg);
  padding: 0;
  cursor: pointer;
  margin-left: 0.5rem;
}

.dropdown-arrow:focus {
  outline: none;
}

.dropdown .dropdown-arrow {
  margin-bottom: 3px;
}

.delete-icon {
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: -6px;
  display: none;
}
.delete-icon-knowledge {
  position: absolute;
  cursor: pointer;
  left: 113px;
  top: -6px;
  display: none;
  z-index: 99;
}
.show-del:hover .delete-icon{
  display: block;
}

.show-del:hover .delete-icon-knowledge{
  display: block;
}

.knowledge-wrap {
  position: relative;
}
.image-attachment .custom-file {
  width:38px !important;
  height:38px !important;
}

.align-mulitiselect .custom-multi-select {
  width: 400px;
  margin-right: 12px;
}
.align-mulitiselect .custom-multi-select .muti-select-wrap {
  height: 34px !important;
  overflow-y: auto !important;
}
.align-mulitiselect .custom-multi-select .dropdown-arrow {
  top: 12px;
  right: 18px;
}
.vertical-center {
  vertical-align: middle !important;
}

/* Button-Group */
.btn-group .btn-primary svg{
  fill: #fff !important;
}

.button_group_container .btn-primary svg path{
  fill: #fff !important;
}

.btn-group svg {
  vertical-align: middle;
}

.custom-multi-select .invalid-feedback {
  color: #d9534f;
}

.job-report .Select-placeholder, .Select--single > .Select-control .Select-value {
  max-width: 93% !important;
}

.job-report .Select-input {
  width: 400px;
}

.qb-radio .form-check-label {
  margin-right: 40px;
}

.tooltip-inner {
  max-width: 320px;
}

.joyride-overlay {
  z-index: 1000 !important;
}

.collapse-text {
  display: flex;
  justify-content: flex-end;
}

.h-auto {
  height: auto !important;
}

.cover {
  object-fit: cover !important;
}

.collapse-icon-wrapper {
  background: #58b247;
  padding: 4px 6px;
  border-radius: 2px;
  color: #fff;
  margin-right: 10px;
}

.collapse-icon-text {
  font-size: 11px;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 5px;
}

.collapse-icon {
  vertical-align: bottom;
  padding-left: 5px;
  margin-left: 18px;
}
.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.f16 {
  font-size: 16px;
}

.f10 {
  font-size: 10px;
}

.joyride-tooltip__close {
  display: none !important;
}

.Select--single .Select-value {
  padding-right: 15px !important;
}

.check-hide {
  display:none;
}
.CircularProgressbar-path {
  stroke: #46B147 !important;
}
.CircularProgressbar-trail {
  stroke: #e7f1bd !important;
}
.CircularProgressbar-text {
  font-size: 30px !important;
  dominant-baseline: central !important;
  fill: #46B147 !important;
}
.joyride-tooltip__close {
  display: none !important;
}

.css-glamorous-div--hvdu2p > div:first-child {
  overflow: auto !important;
}

.cjjWdp {
  opacity: 0.4 !important;
}
.hEACtv {
  height: 12px !important;
}

.fXAXjb {
  width: 12px !important;
}

.toastify {
  z-index: 1060 !important;
}

.session-modal.modal-lg {
  max-width: 72% !important;
}

.session-modal-tasklist {
  max-height: 500px !important;
  overflow: auto;
}
.session-modal-tasklist {
  background-color: #fff !important;
}

.payroll-radio .form-check{
  margin-right: 90px !important;
}

.enable-overtime-field {
  & .invalid-feedback {
    display: none;
  }
}

.autocomplete-drop-menu {
  border: 1px solid #ccc;
  position: absolute;
  width: 100%;
  z-index: 3;
  border-radius: .25rem;
  overflow-x: auto;
  max-height: 8rem;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
}

.button-budget-override .btn-defaultColor {
  background-color: white !important;
  color: #242424;
  border : 1px solid #09819A;
}

.toggleView{
  width: 100%;
  padding-left: 17px;
  button{
    width: 47%;
  }
}


.button-budget-override .btn-primary {
  color: white;
}

.button-job-override {

  Button{
    width: 100%;
    color:#fff;
  }
}

.button-question-override .btn-defaultColor {
  background-color: white !important;
  color: #09819A;
  border : 1px solid #09819A;
  width: 150px;
}

.button-question-override .btn-primary {
  color: white;
  width: 150px;
}



@media screen and (max-width: 1280px){

.side-nav li {
  padding-left: 0px;
}
.side-nav a{
  font-size: 12px;
}
.side-nav{
  & .nav-item {
    line-height: 30px;
    }
   &__list {
     height: 47.5vh;
  }
}
.collapse-icon{
    padding-left: 5px;
    margin-left: 10px;
}
.collapse-icon-text{
    font-size: 9px;
    padding-left: 4px;
    padding-right: 2px;
}
.collapse-icon{
    padding-left: 5px;
    margin-left: 10px;
}
.collapse-icon-text{
    font-size: 9px;
    padding-left: 4px;
    padding-right: 2px;
}
}

.btn-defaultColor {
  background-color: #D3D3D3;
}

.job-task-modal {
  max-width: 560px;
}

.days-button .dropdown-toggle{
  background: lightgrey;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #006CD1;
  background-color: #09819A !important;
  border: 1px solid #B1BABF !important;
  border-radius: 4px 4px 0px 0px !important;
}

.nav-tabs .nav-link {
  color: #001E2E;
  border: inherit;
  border: 1px solid #B1BABF;
}

.bg-blue {
  background-color: #162d6e;
}
.bg-transparent {
  background-color: transparent !important;
}

.link-hover {
  &:hover{
    color: #162d6e;
  }
}

.advance-filter-drop{
  line-height: 1.1rem;
  font-size: 14px;
  padding:0.5rem;
}

.Select-option {
  &:hover {
    background: #9CDAD7 !important;
  }
}

.job-center-advance-select .Select-option {
  line-height: 1rem !important;
  &:hover {
    font-weight:500 !important;
  } 
}

.job-center-advance-filter .advance-filter-drop{
  line-height: 0.8rem !important;
  &:hover{
    font-weight: 500 !important;
  }
} 

.Select-option.is-selected {
  background-color: #9CDAD7 !important;
}

.Select-option.is-focused {
  background-color: #9CDAD7 !important;
  color: #333;
}

.form-modal .modal-content {
  background : #fff;
}

.job-info-modal-height .modal-content{
  height : 90vh;
}

.form-modal .modal-header {
  border-bottom : 2px solid #09819A;
}

.form-modal .modal-footer {
  border-top : 1px solid #d6d6d6;
}

.form-modal .modal-footer-assignJobs {
  justify-content: center;
}

.task-allocation {
  background: #f7f7f7;
}

.link-name {
  color: #0F2BB4 ;
  font-size:13px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover{
    color: #162d6e;
  }
  &:disabled {
    background: #eceeef;
    color: #1A3753;
  }
}

.job-report-multiselect .dropdown-arrow {
  top: 37px !important;
}

.job-report-filter .flatpickr-input , .job-report-filter .input-group-addon {
  height: 34px !important;
}

.button-export{
  color: black !important;
  border: 1px solid #ccc !important;
  background-color: #fff !important;
}

.job-filter {
  .btn-accent {
    background: #46B147;
    margin-right: 8px;
    color: #fff;
    border: none;
    outline: 0;
    line-height: 1.5em;
    padding: 6px 10px;
    font-size: 14px;
    border-radius: 3px;
    &:disabled {
      background: #eceeef;
      color: #1A3753;
    }
  }
}

.dailyTimecard-filter {
  .btn-accent {
    margin-right: 0px !important;
  }
}

.focus-none {
  &:focus {
    outline: none;
  };
}

tbody.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
  border-radius: 4px;
}
tbody.scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
}

.remove-focus{
  outline: none !important;
}

.disable-hover{
  background: white;
}

.table-kiosk-log {

 tbody {
   display:block;
   max-height:375px;
   overflow-y:auto;
 }
 thead, tbody tr {
   display:table;
   width:100%;
   table-layout:fixed;
 }
//  thead {
//    width: calc( 100% - 0.4em )
//  }
}

.header-line{
  border:1px solid #3d556b
}
.select-disabled.is-disabled .Select-value {
  cursor:not-allowed !important;
}

.select-drop-color .Select-arrow{
  border-color: #001e2e transparent transparent;
}
.select-open.is-open > .Select-control .Select-arrow{
  border-color:transparent transparent #001e2e;
}
.select-hover .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #001e2e !important;
}
.employee-user .input-group {
  height : 34px !important;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 1052 !important;
}

.font-12px {
  font-size: 12px;
}

.survey-type-drop .Select-arrow{
  border-color: #001e2e transparent transparent;
}

.job-report-multiselect{
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.job-report-filter{
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.container-padding {
  padding-left: 15px;
  padding-right: 15px;
}

.collapse-menu-icon {
  position: absolute;
  right: 18px;
  z-index:9999;
  top: 10px;
  &:focus {
    outline: none;
  }
}

.font-12px {
  font-size: 12px;
}
.payroll-name {
  font-size: 12px;
}

.pendingVerify{
  z-index: 10;
  width: 100%;
}

.otp{
  height: 35px;
  width: 32px;
  margin-right: 10px;
  text-align: center;
}
.btn-job-success{
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  border-radius: 3px !important;
  border: none !important;
  font-size: 14px !important;
  /* padding: 3px 15px 5px 15px !important; */
  padding: 6px 10px;
  line-height: 1.5em;
}

.ui-card {
  float: none; /* Added */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px; /* 10px rounded corners */
  padding: 15px 15px 30px;
  margin: auto;
  max-width: 40em;
}
.line{
    border: 1px solid #09819A;
    margin-left: 48px;
    width: 10%;
    margin-top: 0px;
    }
.middle{
  margin-top: 10%;
}
.content-area{
  min-height: 100vh !important;
  background: #EFEFEF;
}

.editableText{
  border: transparent;
  background-color: white;
  width: 65%;
}
/*#name{
  width: 30px;
  height: 42px;
  margin-left: 5px;
  text-align: center;
}*/
.editable{
  height: 32px;
}
.resend{
  background-color: transparent !important;
  border-color: transparent !important;
  color: #09819A !important;
  letter-spacing:0.5px;
  &:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #09819A !important;
    cursor: pointer;
    letter-spacing:0.5px ;
  }
}
.okay{
  background-color: #09819A;
  border-color: #09819A;
  border: solid 1px transparent;
  border-radius: 2px;
  color: white;
  max-height: 34px;
  padding: 4px 14px;
}
.form1{

  /*display: block;*/
  width: 100%;
  padding: 0.5rem 0.75rem;
  /*font-size: 1rem;
  line-height: 1.25;*/
  color: #4E4D4A;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.toggleCard{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #EFEFEF;
  border-radius: 0px;
  border-color: #EFEFEF;
}

.newLayoutToggleCard{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 0px;
  border-color: #EFEFEF;
}

.help-text{
  font-size : 12px;
}

.react-tel-input{
  input{
    width: 100% !important;
  }
}
.flag-dropdown .autocomplete-drop-menu {
  width: 220px !important;
  max-height: 10rem !important;
}
.country-flag.disabled {
  background-color: #e0e0db !important;
  cursor: not-allowed;
}
/*.pending{
  height: 8vh;

}*/
.mainbody{
  height: 92vh;
  overflow-x: auto;
}
.header{
  height: auto;
}
.col-min-height{
  min-height:  70vh;
}
.job-report-multiselect{
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.job-report-filter{
  flex: 0 0 20% !important;
  max-width: 20% !important;
}
.col-min-height{
  min-height:  70vh;
}
.table-layout-fixed {
  table-layout: fixed;

}
.table-layout-fixed.table td {
 padding-top: 0.3rem;
 padding-bottom: 0.3rem;
}

.date-span {
  line-height: 27px;
}

.vertical-sub {
  vertical-align: sub !important;
}

.phoneField {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  max-height: 34px;
}

.blue-button {
  height: 34px;
  min-width: auto;
  background-color: #162D6E !important;
  border-color: #162D6E !important;
  font-size: 14px !important;
  padding: 6px 6px  !important;
  &:hover {
    background-color: #162D6E !important;
    border-color: #162D6E !important;
    font-size: 14px !important;
    padding: 6px 6px  !important;
  }
}

.valign-webkit-baseline-middle{
  vertical-align: -webkit-baseline-middle;
}

.static-header-table-tbody {
  display:block;
  max-height:48vh;
  overflow-y:scroll;
}
.static-header-thead,
.static-header-table-tbody > tr {
  display:table;
  width:100%;
  table-layout:fixed;
}
.static-header-thead {
  width: calc( 100% - 0.6em )
}

.static-header-timecard,
.static-header-timecard-tbody > tr {
  display:table;
  table-layout:fixed;
}
.static-header-timecard {
  width: calc( 100% - 0.6em )
}

.toggle-switch .react-switch-handle {
  border: 1.2px solid lightslategrey !important;
}

.payroll-toggle .toggle-switch {
  top: 4px !important;
  margin-right: 5px;
}
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.btn-backdashboard{
    padding: 6px 10px !important;
    background-color: #ffffff !important;
    color: #006CD1 !important;
    font-size:12px !important;
    border:none !important;
    border-radius: 0 !important;
    margin-top: 5px;
    float: right;
}
.btn-backdashboard:focus{
  outline: none !important;
  box-shadow: none !important;
}

.btn-backdashboard:active{
  outline: none !important;
  box-shadow: none !important;
}

.btn-backdashboard:hover{
  outline: none !important;
  box-shadow: none !important;
  color: #162d6e !important;
}

.add-card .StripeElement{
  box-shadow: 2px 2px 2px 2px #efefef !important;
}

.add-card-text{
  color: slategrey;
  font-size: 80%;
  text-align: center;
}

.font-11px {
  font-size: 11px;
}

.meal-card{
  padding-right: 11px;
  padding-left: 20px;
  padding-top:20px;
  background-color: #fff;
  margin-top: 10px;
  max-width: 270px;
  width: 270px;
  padding-bottom: 10px;
  box-shadow: 0px 3px 6px #00000029;
}

.custom-width-dropdown .dropdown-menu{
  min-width: 119px !important;
  width: 100%;
  border-radius: 3px !important;
  .dropdown-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.tag-dropdown{
  padding:5px !important;
  font-size: 12px !important;
  color: black !important;
  border: 1px solid #ccc !important;
  background-color: white !important;
  margin-right:5px;
  text-align: left;
  float: left;

   .dropdown-menu{
    margin-top: 30px;
  }
}

.float-custom .dropdown-toggle:after{
    float: right;
    margin-top: 6px;
  }


.job-report-autocomplete{
  input{
    width: 100%;
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search-position .form-group{
  span{
    width: 100%;
  }
  width: 100% !important;
}

.button-a-tag, .button-a-tag:hover, .button-a-tag:focus{
    background-color: transparent !important;
    color: #006CD1 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 1.4 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0 !important;
  }

.button-a-tag:hover, .button-a-tag:focus{
  color: #096579 !important;
  border: none !important;
  box-shadow: noneimportant;
}

.custom-punchin-width input{
  width: 66px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mileage input{
  text-overflow: ellipsis;
}
.department-dropdown .dropdown-menu {
  .dropdown-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.reportdropdown .dropdown-menu{
  max-height: 200px;
  overflow-y: auto;
  top: 50% !important;
}

.reportemployeedropdown .dropdown-menu{
  max-width: 8rem;
}
.reportemployeedropdown{
  button.dropdown-toggle {
    max-width: 11rem;
  }
}

.reportdropdown-override {
  button.dropdown-toggle {
    height: 33px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.dropdown-override {
  & .dropdown-toggle {
    &:after {
      float: right;
      margin-top: 8px;
    }
  }

.datePicker .input-group{
  width: 168px;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.datePicker input{
  width: 44%;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 8px;
}

@media only screen and (max-device-width: 2000px){
.responsive_width{
  width: 1018px;
}
}

.override_class input{
  width: 88% !important;
}

.override_alignment  input{
  text-align:left !important;
}

.custom_dropdown button{
  width: 172px;
}}

.loadingActivities{
  display: flex;
  justify-content: center;
  margin-top: 50%;
}

.newSliderLoadingActivities{
  display: flex;
  justify-content: center;
  margin-top: 35%;
}

.customisedDatePicker{
  width: 48%;
  // margin-left: 6px;
}

.goToLink{
  font-size: 13px;
}

.commentCustom{
  border: 1px solid #bbb9b9;
  box-shadow: 1px 1px 1px 1px lightgrey;

}

.customName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.widthCustom{
  width: 85%;
}

.job_task_name{
  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  display: inline-flex;
  color: black;
}

.activityCard{
  flex: 1 1 auto;
  padding: 1.25rem;
}

.associated-job{
  color: #0F2BB4 !important;
  text-decoration: underline;
  cursor: pointer;
}

.associated-popup{
  background-color: #EFEFEF !important;
  padding: 15px;
  box-shadow: 2px 4px 4px rgba(78, 77, 74, 0.4);
  border: 1px solid rgba(78, 77, 74, 0.4) !important;
  top: 5px !important;
  max-height: 150px;
  left: -15px !important;
  width: 230px;
  & .popover-inner{
    overflow-y: auto;
    height: 80px;
  }
    &::before{
      top: 50%;
      left: -9px;
      margin-top: -11px;
      border-right-color: #EFEFEF;
      border-left-width: 0;
    }
    &::after{
      content: "";
      border-width: 10px;
      border-right-color: rgba(78, 77, 74, 0.4);
      top: 50%;
      left: -21px;
      margin-top: -11px;
      z-index: -1;
    }
}

.associated-link{
  text-decoration: underline;
  color: #0F2BB4;
}

.hr-style{
  border: 1px solid #09819A;
  background:#09819A;
  margin-left: 20px;
  margin-right: 3rem;
  margin-top: 0rem !important;
  margin-bottom: 2.5rem !important;
  }
.btn-color-blue .btn-primary{
  background-color: #09819A;
  border-color: #09819A;
}
.btn-color-blue .btn-accent
{
  background-color: #09819A;
}
.hr-common{
  border: 1.1px solid #09819A;
  background: #09819A;
  margin-top: 0rem !important;
}
.filterd-alignment{
  text-align: left;
}
.btn-size
{
  width: 125px;
}
.search-Icon-color .search-icon{
  fill:#09819A;
 }
.font-colorInput{
  color:#001E2E !important;
}
.Header-col{
  background-color: #001E2E;
  color:#fff;
  font-weight: lighter !important;
}
.dropdown-customToggle .dropdown-toggle:after{ 
  float:right !important;
  margin-top:0.8vh;
}
.custom-dropdown .dropdown-customToggle{
  margin-top:0rem !important;
}
.text-left{
  text-align: left !important;
}
.btn-width{
  width:104px !important;
}
.close_image{
  float:right;
  margin-top: 12px;
  cursor: pointer;
}
.btn-grp-color
{
  color:#fff;
}
.field-width{
  width:260px;
}
.custom-modal-header .modal-header{
  border: 2px solid #09819A !important;
}
.card-border{
  border-top: 10px solid #09819A;
}
.text_align{
  margin-left:33px !important;
}
.select-drop .Select-arrow{
  border-color: transparent transparent #001e2e !important;
}
.nav-tabSize .nav-item {
  text-align: center !important;
  margin-right: 4px !important;
  width: 148px !important;
}

.justify-center{
  justify-content: center;
}

.custom-padding{
  padding: 0.3rem 0.3rem;
}

.custom-padding-password{
  padding: 20px 20px;
}


.modal-footer{
  border: none !important;
}

.modal-header{
  padding: 30px 30px 5px !important;
  font-size: 12px !important;
  border-bottom: 2px solid #09819A !important;
}


.hide_pwd{
  font-family: 'text-security-disc' !important;
}

.show_pwd{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.hide_pwd::-webkit-input-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.hide_pwd:-ms-input-placeholder {
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.hide_pwd:-moz-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.hide_pwd::-moz-placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

 .validations-box{
    border-radius: 5px;
    background: white;
    position: absolute;
    border: 1.5px solid rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    margin: 0 0px;
    width: 250px;
    top: 22%;
    left: 100%;
    z-index: 999;
  }

  .steps-font{
    font-size: xx-large;
  }

  .shift_info{
      padding-left: 1.9rem;
  }

  .custom_line_height{
    line-height: 1.9rem;
  }

  .date-filter-width{
    .input-group {
      width: 190px;
    }
  }

  .date-search-filter-width{
    .input-group {
      width: 186px;
    }
  }

  .select-input-width{
    Select-input{
      width: 100%;
    }
  }

  .custom-shape{
    margin-top: 26px;
    height: 36px !important;
  }

  .long-job{
    display:inline-block;
    max-width:40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position:absolute;
  }
  
  .custom-height-modal .modal-content{
    min-height: 400px;
  }

  .dept-custom-width{
    padding-right: 0px;
    flex: 0 0 22%;
    max-width: 23%;
  }

  .team-custom-width{
    padding-right: 0px;
    padding-left: 0px;
    flex: 0 0 18%;
    max-width: 19%;

  }

  .emp-custom-width{
    flex: 0 0 24%;
    max-width: 24%;
  }

  .assign-custom-width{
    flex: 0 0 9%;
    max-width: 13%;
  }

  .custom-select-pos .col{
    padding-left: 0px;
    padding-right: 0px;
  } 

  /*.ReactTable .rt-resizer{
    width: 10px !important;
    right: -5px !important;
  }

  .ReactTable .rt-noData{
    top: 45% !important;
    padding: 5px !important;
  }*/

  .employee-div{
    overflow: unset !important;
    position: relative !important;
  }

  .employee-div:hover .hover-employee-div{
    display: inline;
  }

  /*.ReactTable .rt-tr .employee-div .tooltip:hover {
    background: #9CDAD7 !important;
  }*/

  .custom-task-filter form{
    display: inline-block;
    margin-left: 50px;
  }

  .actionCol{
    padding-left: 2px !important;
  }
  .hide-modal{
      display:none;
  }

  .job-start-col{
    padding-left: 14px;
    padding-right: 0px;
  }

  .job-end-col{
    padding-right: 13px
  }

  .checkbox-custom-position{
    .form-check-input{
      position:relative !important;
      top: 2px !important;
    }
    font-weight: 400;
    color: #193652;
  }
  
  .adp-payroll-dropdown .dropdown-arrow{
    top: 15px !important;
  }

  .box {
    background-color: #fff;
    color: black;
    padding: 20px;
    position: absolute;
    z-index: 999;
    top: 42px;
    border-radius: 3px;
    box-shadow: 1px 0px 2px rgba(78, 77, 74, 0.4);
    border: 1px solid rgba(78, 77, 74, 0.4) !important;
    width: 15rem;
    line-height: 2rem;
    outline: none;
  }

  .box.arrow-top:after {
    content: " ";
    position: absolute;
    right: 70%;
    top: -15px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #fff;
    outline:none;
  }

  .box.arrow-top:before {
    content: " ";
    position: absolute;
    right: 70%;
    top: -16px;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid rgba(78, 77, 74, 0.4);
    outline: none;
  }

  .box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 65px;
    border-top: 15px solid transparent;
    border-right: 15px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .box.arrow-left:before {
    content: " ";
    position: absolute;
    left: -16px;
    top: 65px;
    border-top: 15px solid transparent;
    border-right: 15px solid rgba(78, 77, 74, 0.4);
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .box.arrow-bottom:before {
    content: " ";
    position: absolute;
    right: 30px;
    bottom: -16px;
    border-top: 15px solid rgba(78, 77, 74, 0.4);
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
  .box.arrow-bottom:after {
    content: " ";
    position: absolute;
    right: 30px;
    bottom: -15px;
    border-top: 15px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }

  .f-12{
    font-size:12px;
  }

  .custom-filter-dropdown{
    font-size : 12px;
  }


  .custom-filter-dropdown label{
    font-size : 12px;
    padding-bottom : 0px
  }

  .custom-filter-dropdown .dropdown-arrow{
    top: 48px;
  }

  .custom-filter-dropdown .loader-pos{
    top: 40px !important;
  }

.filter-advanced .popover{
  background: transparent;
  top: 14px !important;
  left: -100px !important;

}

.popover{
  max-width: 380px;
}

.override-filter-ui .custom-filter-dropdown {
  margin-top: 6px !important;
}
.create-job-font{
  font-size:14px !important;
}

.material-box {
    background-color: #fff;
    color: black;
    padding: 20px;
    position: absolute;
    z-index: 999;
    top: 42px;
    border-radius: 3px;
    box-shadow: 1px 0px 2px rgba(78, 77, 74, 0.4);
    border: 1px solid rgba(78, 77, 74, 0.4) !important;
    width: 15rem;
    line-height: 2rem;
    outline: none;
  }

  .material-box.arrow-left:after {
    content: " ";
    position: absolute;
    left: -15px;
    top: 130px;
    border-top: 15px solid transparent;
    border-right: 15px solid white;
    border-left: none;
    border-bottom: 15px solid transparent;
  }

  .material-box.arrow-left:before {
    content: " ";
    position: absolute;
    left: -16px;
    top: 130px;
    border-top: 15px solid transparent;
    border-right: 15px solid rgba(78, 77, 74, 0.4);
    border-left: none;
    border-bottom: 15px solid transparent;
  }

.remove-error-space .invalid-feedback{
  min-height: 0px;
}

.remove-lastpass input{
  background-image: none !important;
}

div[id^=__lpform_] {
    display: none;
}

/* The radio button container */
.containerradio {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: not-allowed;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.customcontainerradio{
  margin-bottom: 0px !important;
}

/* Hide the browser's default radio button */
.containerradio input {
  position: absolute;
  opacity: 0;
  cursor: not-allowed;
}

/* Create a custom radio button */
.checkmarkradio {
  position: absolute;
  top: 4px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 50%;
  border: grey solid 2px !important;
}

/* When the radio button is checked, add a white background */
.containerradio input:checked ~ .checkmarkradio {
  background-color: white;
  border: #656565 solid 2px !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmarkradio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerradio input:checked ~ .checkmarkradio:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerradio .checkmarkradio:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #656565;
}

/* The checkmark container */
.checkmarkcontainer {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmarkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarkblue {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 5px;
  border: grey solid 2px !important;
}

/* When the checkbox is checked, add a grey background */
.checkmarkcontainer input:checked ~ .checkmarkblue {
  background-color: #09819A;
  border: 2px solid #09819A !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkblue:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmarkcontainer input:checked ~ .checkmarkblue:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmarkcontainer .checkmarkblue:after {
  left: 4px;
  top: 1px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-disabled-ui{
  color: #888484 !important;
  cursor: not-allowed !important;
}

/* The radio button container for timecard slider activity modal*/
.container {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: white;
  border-radius: 50%;
  border: grey solid 2px !important;
}

/* When the radio button is checked, add a white background */
.container input:checked ~ .checkmark {
  background-color: white;
  border: #09819A solid 2px !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #09819A;
}

.survey-link{
  color: #0F2BB4 !important;
  cursor: pointer;
}


.custom-input-padding input{
  padding-left : 3px;
  padding-right : 3px;
  cursor: pointer;
}

.survey-status .dropdown-menu{
  top: 55% !important;
}

.survey-team-dep .dropdown-menu{
  top: 100% !important;
}

.disabled-feedback{
  cursor: not-allowed;
}

.custom-template-radio {
 @extend .container;
  font-size: 14px !important;
  padding-left: 25px !important;
}
.custom-template-radio .checkmark:after{
  top: 2px !important;
  left: 2px !important;
  width: 7px !important;
  height: 7px !important;
}

.custom-template-checkmark{
  @extend .checkmark;
  height: 15px !important;
  width: 15px !important;
}

.advance-filter-group-by .dropdown-menu{
  top:100% !important;
}

.step_progressbar{
  display:block;
  background-color: white;
}
.progressbar-ul{
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  counter-reset: step;
  margin-bottom: 0px;
}
.progressbar-li{
  display: inline-block;
  position: relative;
  width: 33.33%;
  text-align: center;
  margin: 15px 0;

}
.progressbar-li:before{
  display: block;
  width:50px;
  height: 50px;
  border-radius: 50%;
  line-height:45px;
  text-align: center;
  content: counter(step);
  counter-increment: step;
  background-color: white;
  margin: 0 auto;
  border:  3px solid #B1BABF;
  position: relative;
  z-index: 2;
  color: #B1BABF;
}

.progressbar-li.active{
  color: #09819a;
}

.progressbar-li-two-steps{
  @extend .progressbar-li;
  width: 50% !important;
}

.progressbar-li:after{
  content: '';
  width: 100%;
  height:6px;
  background-color: #B1BABF;
  position: absolute;
  top: 23px;
  left: -50%;
  z-index: 1;
}
.progressbar-li:first-child:after{
  content: none;
}
.progressbar-li.active + .progressbar-li:after{
  background-color: #09819a;
}

.selected-progressbar-li{
  color: #09819A;
}

.selected-progressbar-li:before{
  // @extend .progressbar-li:before;
  background-color: #09819A !important;
  border: 3px solid #09819A !important;
  color: white !important;
}

.selected-progressbar-li-active:before{
  display: block;
  width:50px;
  height: 50px;
  border-radius: 50%;
  line-height:45px;
  text-align: center;
  content: counter(step);
  counter-increment: step;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  background-color: #09819A !important;
  border: 3px solid #fff !important;
  box-shadow: 0 0 0 3px #09819a !important;
  font-size: 19px !important;
  font-weight: bold !important;
  color: white !important;
}

/* Styling for rich text editor toolbox*/
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: #E6E6E6 !important;
}

.tox .tox-tbtn{
  height: 26px !important;
  background-color: white !important;
  border: 1px solid #7E7E7E !important;
  margin-top: 4px !important;
}

.tox-icon{
  svg {
    path {
      fill: #7E7E7E !important;
    }
  }
}

.gm-style .gm-style-iw-d tr {
    height: 30px !important;
}

.gm-style .gm-style-iw-d {
    max-width: 20rem !important;
    overflow: scroll;
}

.showSurveyModal{
  width: 70%;
  transition: .5s;
}

.showEditableSurveyModal{
  width: 100%;
  transition: .5s;
}

.hideSurveyModal{
  width: 100%;
  transition: .5s;
}

.wrapSurveyDetails{
  flex-wrap: wrap;
  display: flex;  
}

.survey-option-width .form-group{
  input{
    width: 100%;
    border: 1px solid #B1BABF;
    border-radius: 3px;
    height: 1.8rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: none;
  }
}

.gm-style-iw-d tr {
    min-height: 30px !important;
}

.gm-style-iw-d {
    max-height: 150px !important;
    overflow-y: auto;
    scrollbar-width: 20px !important; 
}

.employee-job-filter{
  width: 265px;
}

.gm-style-iw-d table {
  margin: 0 auto;
  border-collapse: collapse;
  border-style: hidden;        
}

.tox-notification { 
  display: none !important 
}

.align-table.table thead th:last-child{
  text-align: right !important;
  padding-right: 1rem !important;
 }

 .align-firstcol.table thead th:first-child{
  text-align: left !important;
  padding-left: 1rem !important;
 }

 .right-align-table.table thead th:nth-last-child(3){
  text-align: right !important;
 }

 .right-align-col.table thead th:nth-last-child(2){
  text-align: right !important;
 }

 .right-align-col4.table thead th:nth-last-child(4){
  text-align: right !important;
 }

 .right-align-col5.table thead th:nth-last-child(5){
  text-align: right !important;
 }

 .right-align-col6.table thead th:nth-last-child(6){
  text-align: right !important;
 }

.action-col-width{
  width: 15%;
}

.Select.select-disabled.Select--single.is-clearable.is-disabled.is-searchable{ 
  .Select-placeholder{
    color: white !important;
  }
  .Select-arrow{
    border-color:  #fff transparent transparent;
  }
}

@media screen and (max-width: 1508px){
  .custom-column-width-large{
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .custom-column-width-small{
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .custom-switch-layout{
    float: right !important;
    margin-top: 6px;
    margin-right: 0px !important;
  }

  .custom-switch-decimal-layout{
    margin-top: 6px;
  }
  
}

@media screen and (min-width: 1508px) and (max-width: 1596px){
  .custom-switch-decimal-layout{
    margin-top: 10px;
  }
}

.custom-employee-filter .invalid-feedback {
  display: none !important;
}

/*.float-label .container input, .custom-template-radio input{
  opacity: 1 !important;
  border: none !important;
  background-image: none !important;
  left: 0px !important;
}

.float-label .react-floating-label-input:active{
  label{
    transform: translate3d(26px, -70%, 0px) scale(0.7) !important;
  }
}

.float-label label{
  display: flex !important;
}*/

.floating-form{
  width: 100%;
}

.floating-label { 
  position:relative; 
  margin-bottom:20px; 
}
.floating-input , .floating-select {
  font-size:14px;
  padding:4px 4px;
  display:block;
  width:100%;
  height:30px;
  background-color: transparent;
  border:none;
  border-bottom:1px solid #757575;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.floating-input:focus , .floating-select:focus {
     outline:none;
     border-bottom:2px solid #09819A; 
}

.float-label {
  color:#999; 
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:5px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95%;
}

.floating-input:focus ~ .float-label, .floating-input:not(:placeholder-shown) ~ .float-label {
  top:-18px;
  font-size:14px;
  color:#09819A;
  pointer-events: unset !important;
}

.floating-input:focus ~ .floating-label-image, .floating-input:not(:placeholder-shown) ~ .floating-label-image {
  position: absolute;
  top: -18px !important;
  right: 0px;
}

.floating-select:focus ~ .float-label , .floating-select:not([value=""]):valid ~ .float-label {
  top:-18px;
  font-size:14px;
  color:#09819A;
}

/* active state */
.floating-input:focus ~ .bar:before, .floating-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
  width:50%;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.floating-textarea {
  min-height: 30px;
  max-height: 260px; 
  overflow:hidden;
  overflow-x: hidden; 
}

/* highlighter */
.highlight {
  position:absolute;
  height:50%; 
  width:100%; 
  top:15%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.floating-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* animation */
@-webkit-keyframes inputHighlighter {
	from { background:#09819A; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#09819A; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#09819A; }
  to 	{ width:0; background:transparent; }
}

.floating-label-image{
  position: absolute;
  top: 5px;
  right: 0px;
}

.tiny-label{
  visibility: hidden;
}
.tiny-label-show{
  visibility: visible !important;
}