.schedule-dashboard .fc-time-grid-event {
	padding: 7px 5px !important;
	font-weight: 500;
	cursor: pointer;
	margin-right:5px !important;
}

.fc-timeGridDay-view .fc-time-grid-event {
	width: 160px;
}

.schedule-dashboard .fc-toolbar.fc-header-toolbar {
	margin-bottom: 3px;
}

.schedule-dashboard .fc-center {
	font-size: 8px;
}

.schedule-dashboard .fc-button-primary:focus {
	box-shadow: none;
}

.schedule-dashboard .fc-prev-button {
	padding-left: 0;
}

.schedule-dashboard.content button.fc-prev-button.fc-button.fc-button-primary:after {
	content: "Previous Day";
	vertical-align: inherit;
}

.schedule-dashboard.content button.fc-next-button.fc-button.fc-button-primary:before {
	content: "Next Day";
	vertical-align: inherit;
}

.schedule-dashboard .ptime {
	float: right;
}
.schedule-dashboard.content button.fc-today-button {
	position: absolute;
	top: -62px;
	right: 7.5rem;
}
.swal2-title {
	font-size: 24px !important;
	font-weight: 500 !important;
	color: #242424 !important;
}

.swal2-header {
	border-bottom: 1px solid #5cb85c !important;
	align-items: start !important;
}

.swal2-content {
	text-align: left !important;
	padding-top: 18px !important;
	font-size: 12px !important;
}

.swal2-cancel {
	position: absolute;
	top: 12px;
	right: 8px;
	padding: 0 !important;
	font-size: 20px !important;
	width: 30px;
	font-family: unset;
	color: #000 !important;
}

.swal2-cancel:hover,
.swal2-cancel:active,
.swal2-cancel:focus {
	background: none !important;
	box-shadow: none !important;
	background-image: none !important;
}

.swal2-select {
	border-radius: 3px;
	font-size: 16px;
}

.swal2-close {
	padding: 9px !important;
}

.emp-list {
	margin-top: 10px;
	border-collapse: collapse;
}

.actions-btn {
	justify-content: flex-end !important;
}

.actions-btn .swal2-confirm {
	background: transparent;
	border: none;
	color: #666;
	cursor: pointer;
}

.actions-btn .swal2-confirm:hover {
	color: red;
}

.actions-btn .swal2-confirm:focus {
	outline: none;
}

.actions-wrap .swal2-confirm {
	display: none !important;
}

.swal2-confirm.swal2-styled {
	background-color: #09819a !important;
}

.emp-list th {
	color: #fff;
	background-color: #afafaf;
	padding: 6px;
}

.emp-list td {
	padding: 6px;
	vertical-align: middle;
	border-bottom: 1px solid #dee2e6;
}

.close {
	cursor: pointer;
}

.close:hover {
	opacity: 1;
}

.close:before,
.close:after {
	position: absolute;
	content: ' ';
	height: 14px;
	width: 2px;
	background-color: #333;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

.pb-10 {
	padding-bottom: 10px;
}

.sub-employeeDot{
	margin-right: 2px;
}


.no-record-container{
	position: absolute;
    top: 40%;
	align-self: center;
}

.no-record-text{
	font-size: 18px;
}