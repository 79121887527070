/*!
 * Bootstrap v4.0.0-alpha.6 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
// @import "./scss/functions.scss";
@import "./scss/variables";
@import "./scss/mixins";
@import "./scss/custom";
@import "./color";
@import "./table";
@import "./button";
@import "./form";
@import "./dropdown";
@import "./pagination";
@import "./custom-file";

// Reset and dependencies
@import "./scss/normalize";
@import "./scss/print";

// Core CSS
//@import "./scss/reboot";
@import "./scss/type";
@import "./scss/images";
@import "./scss/code";
//@import "./scss/grid";
@import "./scss/tables";
@import "./scss/forms";
//@import "./scss/buttons";

// Components
@import "./scss/transitions";
@import "./scss/dropdown";
@import "./scss/button-group";
@import "./scss/input-group";
@import "./scss/custom-forms";
//@import "./scss/nav";
//@import "./scss/navbar";
@import "./scss/card";
@import "./scss/breadcrumb";
@import "./scss/pagination";
@import "./scss/badge";
@import "./scss/jumbotron";
@import "./scss/alert";
//@import "./scss/progress";
@import "./scss/media";
@import "./scss/list-group";
@import "./scss/responsive-embed";
@import "./scss/close";

// Components w/ JavaScript
@import "./scss/modal";
@import "./scss/tooltip";
@import "./scss/popover";
@import "./scss/carousel";

// Utility classes
@import "./scss/utilities";
