.schedule-dashboard .fc-toolbar.fc-header-toolbar {
	margin-bottom: 3px;
}

.schedule-dashboard .fc-center {
	font-size: 8px;
}

.schedule-dashboard .fc-button-primary:focus {
	box-shadow: none;
}

.fc-time-grid-container, .fc-day-grid-container{
	overflow: unset !important;
}

.schedule-dashboard .fc-center h2:before {
	content: 'Manage Shifts, ';
}

.schedule-dashboard-custom .fc-center h2:before {
	content: 'Manage Shifts, ';
}

.schedule-dashboard button.fc-prev-button.fc-button.fc-button-primary:after {
	content: "Previous";
	vertical-align: inherit;
	font-weight: bold;
}

.schedule-dashboard button.fc-next-button.fc-button.fc-button-primary:before {
	content: "Next";
	vertical-align: inherit;
	font-weight: bold;
}

.schedule-dashboard button.fc-today-button.fc-button.fc-button-primary{
	font-weight: bold;
}

.schedule-dashboard button.fc-prev-button.fc-button.fc-button-primary:hover, .schedule-dashboard button.fc-next-button.fc-button.fc-button-primary:hover, .schedule-dashboard button.fc-today-button.fc-button.fc-button-primary:hover {
	text-decoration: underline;
  	color: #006cd1;
}

.fc-short .Description, .fc-short .sub-description{
	display:none !important;
}

.fc-time-grid-event-inset .Description, .fc-time-grid-event-inset .sub-description{
	font-weight: 400;
	white-space: nowrap;
}

.fc-event .tooltip_desc{
	position:absolute;
	display: none;
    width: fit-content; 
    max-width: 200px;
  	overflow-wrap: break-word;
  	white-space: normal;
    text-align: right;
    top: 10%;
    right: 100%;
    font-size: 10px;
    padding: 10px 10px;
    background: rgba(0,0,0);
    z-index:999;
}

.fc-event:hover .tooltip_desc, .fc-event:hover .tooltip_desc_dashboard {
	display: block !important;
}

.fc-event .tooltip_desc_dashboard{
	position:absolute;
	display: none;
    width: fit-content; 
    text-align: right;
	top: 12%;
	max-width: 200px;
  	overflow-wrap: break-word;
  	white-space: normal;
	left: 0;
    font-size: 10px;
    padding: 5px 10px;
    background: rgba(0,0,0);
    z-index:999;
}

input::-webkit-input-placeholder {
    font-size: 13px;
}

.ifaNov {
	flex-direction: row !important;
}

.gdLlNy {
	margin-right: 8px !important;
}

.lcqKHO {
	margin-right: 10px !important;
}

.sub-employeeDot{
	margin-right: 2px;
}
