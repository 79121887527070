.slider-table-header-wrapper{
    width:100%;
    align-items: center;
    align-self: center;
    margin-bottom: -20px;
    margin-top: -15px;
   
}
.header-divider-wrapper{
    position: fixed;
    width:100%;
  //  justify-content: space-around;
    align-items: center;
}
.slider-table-content-wrapper{
    width:100%;
  //  justify-content: space-around;
    align-items: center;
}
.table-header-text{
    font-size: 18px;
    font-weight: 300;
   
}

.table-content-text{
    font-size: 14px;
    font-weight: 300;
}

.horizontal-line{
    border-width:medium;
  }

.table-pagination-pageNo{
    height: 24px;
    background-color: white;
    width: 20px;
    color: black;
    border: 1px solid #C0C0C0;
    display: flex;
    justify-content: center;
    border-radius: 0rem;
}

.table-pagination-button-wrap{
    border-radius: 0rem;
    height: 24px;
    width: 24px;
}

.historySliderLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
