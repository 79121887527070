@font-face {
   font-family: MuseoSans;
   font-weight: 100;
   src: url('./fonts/MuseoSans_100.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 300;
   src: url('./fonts/MuseoSans_300.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 500;
   src: url('./fonts/MuseoSans_500.otf');
 }
 @font-face {
   font-family: MuseoSans;
   font-weight: 700;
   src: url('./fonts/MuseoSans_700.otf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'MuseoSans',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: 'MuseoSans',
    monospace;
}

table {
  display: table;
  width: 100%;
}

tr {
  display: table-row;
  width: 100%;
}

th {
  display: table-cell;
}

@media screen and (max-width: 479px) {

  table,
  tr {
    display: block;
  }

  th {
    display: block;
  }
}

div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 4px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

input:checked + span > span {
    left: calc( 38px - 22px ) !important;
    background: #ffffff !important;
}

.ipZqBA{
  background-color:white !important;
}

.dawACu{
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px !important;
}

.cursor-pointer{
  cursor: pointer;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after{
  background: transparent !important;  
  color: #242424 !important;
}

.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: #09819a !important;
}

.e-input-group:not(.e-filled) .e-input-group-icon::after, .e-input-group.e-control-wrapper:not(.e-filled) .e-input-group-icon::after {
  color: #09819a !important;
}

.e-input-group .e-input-group-icon, .e-input-group.e-control-wrapper .e-input-group-icon{
  color: #09819a !important;
}

.e-input-group, .e-input-group.e-control-wrapper {
  border-radius: 0.5rem !important;
  border: 1px solid #CBCBCB !important;
}

.e-input.e-error, .e-input.e-error:focus, .e-input-group.e-error input.e-input, .e-input-group.e-control-wrapper.e-error input.e-input, .e-input-group.e-control-wrapper.e-error .e-input-group-icon, .e-input-group.e-error .e-input-group-icon, .e-input-group.e-error textarea.e-input, .e-input-group.e-control-wrapper.e-error textarea.e-input{
  border-color: rgba(0, 0, 0, 0.42);
}

.e-input:not(:valid), .e-input:valid, .e-float-input.e-control-wrapper input:not(:valid), .e-float-input.e-control-wrapper input:valid, .e-float-input input:not(:valid), .e-float-input input:valid, .e-input-group input:not(:valid), .e-input-group input:valid, .e-input-group.e-control-wrapper input:not(:valid), .e-input-group.e-control-wrapper input:valid, .e-float-input.e-control-wrapper textarea:not(:valid), .e-float-input.e-control-wrapper textarea:valid, .e-float-input textarea:not(:valid), .e-float-input textarea:valid, .e-input-group.e-control-wrapper textarea:not(:valid), .e-input-group.e-control-wrapper textarea:valid, .e-input-group textarea:not(:valid), .e-input-group textarea:valid{
  box-shadow: #CBCBCB;
}

.e-input-group, .e-input-group.e-control-wrapper, .e-float-input, .e-float-input.e-input-group, .e-float-input.e-control-wrapper, .e-float-input.e-input-group.e-control-wrapper {
  background: transparent;
  color: rgb(110, 108, 108) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: MuseoSans !important;
  padding-left: 6.5px !important;
}

.input.e-input::selection, textarea.e-input::selection, .e-input-group input.e-input::selection, .e-input-group.e-control-wrapper input.e-input::selection, .e-float-input input::selection, .e-float-input.e-control-wrapper input::selection, .e-input-group textarea.e-input::selection, .e-input-group.e-control-wrapper textarea.e-input::selection, .e-float-input textarea::selection, .e-float-input.e-control-wrapper textarea::selection {
  background: #3297f5 !important;
  color: #fff;
}

.timefence-border {
  border: 1px solid lightgray !important;
  border-radius: 4px !important; 
  padding: 6.5px;  
  box-shadow: 0 0 2px 2px #d3d3d3;
}

.e-input-group .e-input[readonly], .e-input-group.e-control-wrapper .e-input[readonly], .e-float-input input[readonly], .e-float-input.e-control-wrapper input[readonly], .e-float-input textarea[readonly], .e-float-input.e-control-wrapper textarea[readonly] {
  cursor: pointer;
}

.e-input-group-icon.e-date-icon::before, *.e-control-wrapper .e-input-group-icon.e-date-icon::before{
  color: #09819a;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{
  color: #09819a !important;
  border: 1px solid #09819a !important;
}

.e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day{
  border: 1px solid #09819a !important;
}

.e-btn.e-flat.e-primary, .e-css.e-btn.e-flat.e-primary{
  color: #fff !important;
}

.e-btn.e-primary, .e-css.e-btn.e-primary{
  background-color: #09819a !important;
  color: #fff !important;
}

.e-calendar .e-content td.e-selected span.e-day,.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #09819a !important;
  border: none;
  border-radius: 50%;
  color: #fff;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #09189a !important;
  border: 1px solid #09819a !important;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

.e-calendar .e-content td.e-today span.e-day, .e-calendar .e-content td.e-focused-date.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-today span.e-day, .e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none !important;
  border-radius: 50% !important;
  color: #09819a !important;
}

.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover {
  background-color: #eee;
  border-color: #fff;
  color: #09819a !important;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
  background-color: #fff;
  color: #09819a !important;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #09819a !important;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #09819a !important;
}

.graceperiod-text {
  white-space: nowrap;
  overflow: visible;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  width: auto !important;
  height: 100% !important; 
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 6.5px !important;
  padding-right: 6.5px !important;
}

.e-datetime-wrapper .e-date-icon {
  order: 3;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #09819a !important;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #09819a !important;
}

.e-time-wrapper .e-time-icon.e-icons, *.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {
 font-size: 20px !important;
}

.info-icon-align{
  vertical-align: middle;
  padding-bottom: 3px;
}

.bwJYYH{
  background-color: white !important;
}

.select-disable{
  background-color: #070707 !important;
  text-overflow: ellipsis;
}
.select-disable::placeholder{
  color:white !important;
}
