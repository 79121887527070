.fc-event {
	font-size: 10px !important;
}

.fc-button-group .fc-button-primary,
.fc-today-button {
	background-color: #B8B8B8;
	border: none;
	text-transform: capitalize;
	padding: 9px 10px;
	font-size: 14px;
	border-radius: 3px;
}

.fc-button-group .fc-button-primary:focus,
.fc-today-button:focus {
	box-shadow: none !important;
}

.fc-button-group .fc-button-primary:hover {
	background-color: #09819a;
}

.fc-button-group .fc-button-primary.fc-button-active {
	background-color: #09819a;
	border-color: #09819a;
}

/*.fc-button-group {
	margin-right: 16% !important;
}*/

.fc-next-button,
.fc-prev-button,
.fc-next-button:hover,
.fc-prev-button:hover,
.fc-next-button:not(:disabled):active,
.fc-next-button:not(:disabled).fc-button-active,
.fc-prev-button:not(:disabled):active,
.fc-prev-button:not(:disabled).fc-button-active {
	font-size: 13px;
	color: #636c72;
	background-color: transparent;
	border-color: transparent;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.fc-toolbar.fc-header-toolbar {
	position: relative;
}

.fc-button-group {
	position: absolute;
	top: -69px;
	right: 22.2rem;
	height: 37px;
}

.fc-today-button{
	position: absolute;
	top: -69px;
	right: 14.5rem;
	background-color: #09819a !important;
	color: #fff !important;
	text-decoration: none !important;
	cursor: pointer !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	opacity: 1!important;
	font-weight: 500 !important;
}

.fc-today-button,
.fc-today-button:hover,
.fc-today-button:not(:disabled):active,
.fc-today-button:not(:disabled).fc-button-active {
	font-size: 13px;
	color: #636c72;
	background-color: transparent;
	border-color: transparent;
}
.fc-widget-header {
	color: #3d556b;
	font-size: 12px;
}

.fc-center{
	text-align: center;
}

.fc-day-top, .fc-more, .fc-axis{
	font-size: 12px !important;
}

.fc th{
  background: #FFFFFF !important;
}

 td .fc-past {
  background: #f6f6f6;
}

.valign-btm {
	vertical-align: text-bottom;
}

.tick-icon::before {
	content: '';
	display: inline-block;
	width: 3px;
	height: 6px;
	margin-right: 6px;
	margin-bottom: 1px;
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

.cross-icon:before {
	content: 'x';
	color: #fff;
	font-weight: 600;
	font-size: 12px;
	font-family: inherit;
	padding-right: 4px;
	line-height: 12px;
}

.shift-dot::before{
	content: '';
	width: 8px;
	border-radius: 50%;
	background-color:red;
	display: inline-block;
	height: 8px;
}

.emp-name {
	display: inline-block;
	/*width: 55px;*/
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: bottom;
}
.emp-tag {
	border-radius: 2px;
	padding: 1px 4px;
	margin-bottom: 3px;
}
.absent_punch_in {
	background: grey;
}

.on_time_punch_in {
	background: green;
	margin-bottom: 3px;
  	padding: 0 2px;
}

.early_punch_in {
	background: red;
	margin-bottom: 3px;
  	padding: 0 2px;
}

.late_punch_in {
	background: red;
	margin-bottom: 3px;
  	padding: 0 2px;
}

.ptime.late_punch_in:after {
	content: '(Late)';
}

.ptime.early_punch_in:after {
	content: '(Early)';
}

.fc-title{
	text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fc-content-skeleton .fc-day-grid-event .fc-content{
	max-height: 50px;
  	overflow-y:auto;
  	scrollbar-width: none; /* Firefox */
  	-ms-overflow-style: none; 
}

.fc-content-skeleton .fc-day-grid-event .fc-content::-webkit-scrollbar { 
    display: none;  /* Chrome Safari */
}

.fc-popover .fc-day-grid-event{
	max-height: fit-content;
}

.fc-popover .fc-event-container {
    padding: 10px;
    max-height: 400px;
    overflow-y: auto; 
}

.fc-popover .fc-event-container .fc-event:hover .tooltip_desc{
	display: none !important;
}

.fc-time-grid-event .fc-content{
	overflow-y: auto;
  	max-height: 100%;
  	overflow-x: hidden;
  	scrollbar-width: none; /* Firefox */
  	-ms-overflow-style: none; 
}

.fc-time-grid-event .fc-content::-webkit-scrollbar{
	display: none;
}

.fc-view-container {
  overflow-y: scroll !important;
  height: 80vh !important;
}
@media only screen and (min-height: 900px) and (max-height: 1249px){
  .fc-view-container {
	  overflow-y: scroll !important;
	  height: 70vh !important;
	}
}
@media only screen and (min-height: 1250px){
  .fc-view-container {
	  overflow-y: scroll !important;
	  height: 75vh !important;
	}
}

.fc-time-grid-event .fc-time{
	display: contents;
}

.fc-time-grid-event.fc-short .fc-time{
	padding-top: 1px;
} 
.fc-time-grid-event.fc-short .fc-title{
	padding-top: 1px;
}